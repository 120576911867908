import { isProdSite } from './site.js';
import { hasPreviewPage } from './previewUtil.js';
import CdtsConfigurator from '../config/cdts.config.js';
import Module from './Module.js';
import { Language } from '../types/template.js';
import i18next from 'i18next';

export default class ExportToWordModule extends Module {
  moduleReady: boolean = false;
  readonly exportDetailsId = 'export2word';
  readonly exportButtonId = 'exportToWord';

  getModuleName(): string {
    return 'exportToWord';
  }

  doLogic(): Promise<unknown> {
    this.initExportToWord();
    return Promise.resolve();
  }

  initExportToWord() {
    if (isProdSite() || hasPreviewPage()) {
      return;
    }
    const exportToWord =
      Language.ENGLISH === i18next.language ? 'Export to Word' : 'Exporter vers Word';

    const previewPageInfo = document.querySelector('h1');
    if (previewPageInfo != null) {
      previewPageInfo.insertAdjacentHTML(
        'beforebegin',
        `<details id="${this.exportDetailsId}" class="alert alert-info">
            <summary><span role="heading" aria-level="6" class="h6">Information</span></summary>
            <div class="small">
                <button id="${this.exportButtonId}" type="button" class="btn btn-default mrgn-bttm-lg mrgn-tp-lg">${exportToWord}</button>
            </div>        
            </details>`
      );
      const exportButton = document.getElementById(this.exportButtonId);
      if (exportButton) {
        exportButton.onclick = async () => {
          await this.export2word();
          // Add your export to Word logic here
        };
      }
    }

    this.moduleReady = true;
  }

  async export2word() {
    const mainElement = document.querySelector('main');
    if (!mainElement) {
      console.error('Main element not found');
      return;
    }
    const imgElements = mainElement.querySelectorAll('img');

    for (const img of imgElements) {
      const src = img.getAttribute('src');
      if (src) {
        try {
          const base64 = await this.getBase64FromImageUrl(src);
          img.setAttribute('src', base64);
        } catch (error) {
          console.error(`Error getting base64 from image ${src}`, error);
        }
      }
    }

    const parser = new DOMParser();
    let content = mainElement.innerHTML;
    if (content == null) {
      console.error('No content found to export');
      return;
    }
    const doc = parser.parseFromString(content, 'text/html');
    const element = doc.querySelector(`#${this.exportDetailsId}`);
    if (element != null) {
      element.remove();
    }
    content = new XMLSerializer().serializeToString(doc);

    let fullPage = `<html>
          <head>
            <link href="${CdtsConfigurator.getUrl()}/wet-boew/css/theme.min.css" type="text/css" rel="stylesheet">
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;

    fullPage = fullPage.replace('class="wb-inv"', '');
    fullPage = fullPage.replace('<head>', '<head><meta charset="utf-8">');

    let filename = window.location.pathname;
    filename = filename.replace(/^\//, '');
    filename = filename.replace(/\//g, '_');
    filename = filename.replace(/\.shtml/, '.doc');
    const blob = new Blob([fullPage], { type: 'text/plain;charset=utf-8' });

    this.saveAs(blob, filename);
  }

  saveAs(blob: Blob, filename: string): void {
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url); // Clean up the URL
  }

  async getBase64FromImageUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        } else {
          reject(new Error('Canvas context is not available'));
        }
      };
      img.src = url;
    });
  }
}
