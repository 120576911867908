import ExportToWordModule from './modules/exportToWord';
import WhenReady from './WhenReady';
import { ConfigModuleFactory } from './config/factory.config.js';
import type { TemplateFrontEndGlobals } from './types/script.js';
import type { CustomWindow } from './types/template.js';
declare let window: CustomWindow;

WhenReady.whenTemplateReady(() => {
  new ExportToWordModule().run().catch((error) => {
    console.error(error);
  });

  const config = ConfigModuleFactory.getConfig(
    (window.templateFrontEnd as TemplateFrontEndGlobals).site
  );

  const footerAssets = config?.getFooterAssets();
  footerAssets?.forEach((footerAsset) => {
    if (!footerAsset.isInjectable || footerAsset.isInjectable.call(this)) {
      if (typeof footerAsset.asset === 'string') {
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('src', footerAsset.asset);
        if (document.body) {
          // Insert the script before the closing </body> tag
          document.body.appendChild(scriptElement);
        } else {
          console.error('Body element not found');
        }
      } else {
        (footerAsset.asset as () => unknown)();
      }
    }
  });
});
