import { initExportToWord } from './modules/exportToWord';
import WhenReady from './WhenReady';
import type { ModuleReady } from './types/script.ts';
import ConfigModuleFactory from './config/factory.config.ts';

window.templateFrontEndModulesReady = {
  template: {
    adobeAnalytics: false,
    breadcrumbs: false,
    exportToWord: false,
    onCDTSPageFinalized: false
  },
  esdcPrv: {
    articles: false,
    blogs: false,
    caroussel: false,
    catquery: false,
    moreNewsfeed: false,
    newsfeed: false,
    randomSurvey: false,
    shareThisPageBtn: false
  }
} as ModuleReady;

WhenReady.whenTemplateReady(() => {
  initExportToWord();

  const config = ConfigModuleFactory.getConfig(window.templateFrontEnd.site);
  const footerAssets = config?.getFooterAssets();
  footerAssets?.forEach((footerAsset) => {
    if (footerAsset.isInjectable?.call(this)) {
      if (typeof footerAsset.asset === 'string') {
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('src', footerAsset.asset as string);
        if (document.body) {
          // Insert the script before the closing </body> tag
          document.body.appendChild(scriptElement);
        } else {
          console.error('Body element not found');
        }
      } else {
        (footerAsset.asset as () => void)();
      }
    }
  });
});
